import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const About = props => (
  <Layout>
    <SEO title="About theoriginal151.com" />
    <div className="border-b-2 border-black md:mt-16">
      <div className="text-right text-xl font-press-start-2p">
        <div className="mt-6 mb-4">000</div>
      </div>
    </div>
    <div className="border-b-2 border-black">
      <div className="flex text-left justify-between">
        <div className="mt-32 mb-4">
          <h1 className="font-press-start-2p inline-block">About</h1>
        </div>
      </div>
    </div>
    <main className="md:text-xl">
      <p>
        I began studying Japanese at the start of 2019 as something I have
        wanted to pursue for many years now. My primary reason for wanting to
        learn the language was definitely to gain a deeper understanding of
        Japanese videogames, particularly those found on Nintendo consoles.
      </p>
      <p>
        Many of the Nintendo games make an effort to be as approachable by all
        age groups. One way they accomplish this is to ensure all games can be
        read by all age groups through use of Hiragana throughout. In the early
        days of videogames, Hiragana was used due to memory limitations - it's
        way smaller storing the text for just 46 Hiragana and 48 Katakana
        characters compared to the thousands of Kanji.
      </p>
      <p>
        I have always loved playing Nintendo games and Pokemon was one of the
        first games I bought for my GameBoy back in the 1990's. What better way
        to investigate the interesting language than to compare the English and
        Japanese translations for all of the original 151 Generation One
        Pokemon.
      </p>
    </main>
  </Layout>
);

export default About;
